import React from 'react';
import './banner.css';
import { Carousel } from 'react-bootstrap';
export const Banner = ({height,images}) => {
	let imagesRequire = [];
	for(const image of images){
		try{
			const desktopImage = require(`./../../assets/img/banners/${image.desktop_url}`);
			const mobileImage = require(`./../../assets/img/banners/${image.mobile_url}`);
			imagesRequire.push({
				desktop_url : desktopImage,
				desktop_type : "image/"+image.desktop_url.split(".")[1],
				mobile_url : mobileImage,
				mobile_type: "image/"+image.mobile_url.split(".")[1],
			})

		}catch(err){
			console.log(err.message)
		}
	}
	return <div style={{marginTop: (height + 5) + "px"}} className="banner-container">
		<Carousel>
			{imagesRequire.map((image,i) => {
				return <Carousel.Item key={i} controls={false}>
					<picture>
						<source media="(min-width: 864px)" srcSet={image.desktop_url} type={image.desktop_type}/>
						<img src={image.mobile_url} width="100%" alt="" type={image.mobile_type}/>	
					</picture>
				</Carousel.Item>
			})}	
		</Carousel>	
	</div>
}