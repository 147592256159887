//export const API_AWS = "https://1dxhjjskxe.execute-api.us-east-1.amazonaws.com";
//export const API_AWS = "https://1dxhjjskxe.execute-api.us-east-1.amazonaws.com/ClaroLandingAPI";

//export const API_AWS = "https://api.distrimovil.pe/ClaroLandingAPI_AWS";                         
//export const API_KONECTA = "https://apps.grupokonecta.com/ws_Claro_Pe/ws_landing.php";

const prod = {

    API_AWS: "https://api.distrimovil.pe/ClaroLandingAPI_Movil",
    API_KONECTA: "https://apps.grupokonecta.com/ws_Claro_Pe/ws_landing.php"

};

const dev = {
    
    // API_AWS: "https://1dxhjjskxe.execute-api.us-east-1.amazonaws.com/ClaroLandingAPI",
    API_AWS: "https://apps.grupokonecta.com/ws_Claro_Pe/ClaroLandingAPI_Movil.php",
    API_KONECTA: "https://apps.grupokonecta.com/ws_Claro_Pe/ws_landing_dev.php"

};

export const API_URL = process.env.NODE_ENV === 'development' ? dev : prod;