import React, { useState } from 'react';
import {Row, Col, Button, Collapse} from 'react-bootstrap';
import max from './../../assets/img/Max.svg';
import max_ilimitado from './../../assets/img/MaxIlimitado.svg';
import max_netflix from './../../assets/img/cellphone.png';
import dos_play from './../../assets/img/planes/2play.svg';
import tres_play from './../../assets/img/planes/3play.svg';
import dos_play_tv from './../../assets/img/planes/Icono2Play-Int-Tv.png';
import { Paquete } from './Paquete';
import { useModal } from './../../hooks/useModal';
import { FormCallme } from './../Form';


export const Plan = ({plan}) => {

	const {setShow,setBody} = useModal();
	const [collapse,setCollapse] = useState(false);
	const tipoPlan = (plan) => {
		if(plan.plan === "max"){
			return <div className="d-flex justify-content-between px-4 align-items-center top-plan">
					<img src={max} width="70" alt="" style={{marginBottom: "-12px"}}/>
					<span className="price">S/. {plan.precio}</span>
				</div> 
		}
		if(plan.plan === "max_ilimitado"){
			return <div className="d-flex flex-column pt-4 align-items-center px-4 top-plan">
				<img src={max_ilimitado} width="60%" alt="" style={{marginBottom: "-12px"}}/>
				<span className="price mt-2"><span className='fs-4'>S/. </span>{plan.precio}</span>
			</div>
		}
		if(plan.plan === "max_play_netflix"){
			return <div className="d-flex justify-content-between pt-3 px-4 align-items-center divider top-plan">
				<div className="d-flex flex-column">
					<img src={max_netflix} width="20%" className="mb-1" alt=""/>	
					<p className="text-claro fw-bold fs-4">NETFLIX</p>
				</div>
				<span className="price">S/. {plan.precio}</span>
			</div>
		}
		if(["dos_play_internet_telefonia","dos_play_fibra_internet_telefonia","tres_play","tres_play_fibra_optica"].includes(plan.plan)){
			return <div className="d-flex flex-column pt-3 px-4 align-items-start divider top-plan">
				{plan.plan.includes("dos_play")
				?<img src={dos_play} width="70" className="mb-1" alt=""/>	
				:<img src={tres_play} width="100" className="mb-1" alt=""/>}
				
				<p className="text-red fw-bold fs-2">{plan.velocidad_internet}</p>
			</div>
		}
		if(plan.plan === "dos_play_internet_television" || plan.plan === "dos_play_fibra_internet_television"){
			return <div className="d-flex flex-column pt-3 px-4 align-items-start divider top-plan">
				<img src={dos_play_tv} width="70" className="mb-1" alt=""/>	
				<p className="text-red fw-bold fs-2">{plan.velocidad_internet}</p>
			</div>
		}
		if(plan.plan === "netflix"){
			return <div className="d-flex flex-column pt-3 px-4 align-items-start divider top-plan">
				{plan.label_destacado === "2Play"
				?<img src={dos_play} width="70" className="mb-1" alt=""/>
				:<img src={tres_play} width="100" className="mb-1" alt=""/>}
				
				<p className="text-red fw-bold fs-2">{plan.velocidad_internet}</p>
				<span className="label-netflix fw-normal">NETFLIX</span>
			</div>	
		}
	}

	const openFormCallme = (e,plan) => {
		setBody(<FormCallme isModal={true} plan={plan} />);
		setShow(true);
	}


	const imgErrorHandler = (uri,width="300",alt="") => {
		try{
			const image = require(`./../../assets/img/icons/${uri}`);
			return <img src={image} alt="" width={width}/>
		}catch(err){
			//console.log(err)
			return <h3> </h3>
		}
	}
	const descPlan = (plan) => {
		//console.log(plan.descripcion);
		if(plan.descripcion === "todo_ilimitado"){
			return <div className="text-center line-height-1">
				<b className="todo-ilimitado">TODO ILIMITADO</b>
				<p className="todo-ilimitado-desc mt-1">GIGAS, MINUTOS Y SMS</p>
			</div>
		}
		if(plan.descripcion === "precio"){
			return <div className="text-center fw-bold line-height-1">
				<p className="m-0 fs-6 mb-1">Por {plan.meses_descuento} meses</p>
				<p className="fs-40 m-0">S/{plan.precio_primer_mes}</p>
				<p className="m-0 fw-normal mt-1 fs-sm-1 fw-bold">Luego S/{plan.precio}</p>
			</div>
		}
		return <>
			<Row className="text-center">
				<p className="plan-label m-0">REDES SOCIALES</p>
				<p className="plan-label-sm m-0">Ilimitados</p>
			</Row>
		</>
	}
	
	return <div className={`plan ${!!plan.plan_destacado?"destacado" : ""}`} style={ plan.destacado_color &&  {boxShadow : `0 0 0 9px ${plan.destacado_color}`}}>
		{!!plan.label_destacado && <span className="label" style={{backgroundColor: !!plan.label_color??"var(--red)"}}>{plan.label_destacado}</span>}
		<div className="plan-contenido">
			{plan.descuento && <span className="descuento" style={{backgroundColor: plan.label_color}}><span className="value">-{plan.descuento}%</span></span>}
			{plan.fibra && <div className="fibra">FIBRA</div>}
			{tipoPlan(plan)}
			<div className="py-3 redes-sociales">
				{descPlan(plan)}
			</div>
			<div className="mb-5 p-3">
				<div className='px-1 mb-5 paquetes-container'>
					<div className=''>
					{plan.paquetes
						//.sort( (a,b) => b.orden - a.orden)
						.filter(p => p.value !== "" && p.value !== null)
						.map( (p,i) => <Paquete data={p} key={i}/>)
					}
					</div>
				</div>
				<div className="px-1 pb-3">
					<Button variant="claro" className="w-100 text-light" onClick={(e) => openFormCallme(e,plan)}>¡Lo Quiero!</Button>	
				</div>
			</div>
		</div>
	</div>
}