import React,{useState, useEffect} from 'react';
import { Row,Col, Carousel } from 'react-bootstrap';
import { Plan } from './Plan';
import nextI from "./../../assets/img/icons/right.svg";
import prevI from "./../../assets/img/icons/left.svg";
export const ListaPlanes = ({title,desc,planes = [],isLoading,center = false}) => {
	const [matches, setMaches] = useState(window.matchMedia("(min-width: 864px)").matches)
	useEffect(() => {
		window
		.matchMedia("(min-width:864px)")
		.addEventListener("change",e => setMaches(e.matches))
	},[]);

	const prev = <span className="carousel-control-prev-icon carousel-planes"><img src={prevI} alt="prev-icon"/></span>
	const next = <span className="carousel-control-next-icon carousel-planes"><img src={nextI} alt="next-icon"/></span>
	return (<Row>
	{planes.length > 0 && !isLoading &&
		<>
		<div className="my-4 row">
			<div className="mb-5 text-center">
				<h2 className="h2 fw-bold m-0">{title}</h2>
				<p className="text-muted fs-5">{desc}</p>
			</div>
		</div>
		{matches
		?(<>
			{center
			?<div>
				<Row className="justify-content-center">
				{planes.slice(0,3)
						.map((p,i) => 
					<Col xl={3} lg={4} md={6} key={i}>
						<Plan  plan={p}/>
					</Col>)
				}
				</Row>
				<Row className="justify-content-center">
				{planes.slice(3,planes.length)
						.map((p,i) => 
					<Col xl={3} lg={4} md={6} key={i}>
						<Plan  plan={p}/>
					</Col>)
				}
				</Row>
			</div>
			:<Row className="justify-content-center">
				{planes.map((p,i) => 
					<Col xl={3} lg={4} md={6} key={i}>
						<Plan  plan={p}/>
					</Col>)
				}
			</Row>}
		</>)
		:(<Row className="center-x">
		<Carousel controls={true} interval={5000} nextIcon={next} prevIcon={prev}>
			{planes.map((p,i) => 
				<Carousel.Item key={i}className="px-2 py-5">
					<Plan plan={p}/>	
				</Carousel.Item>)}
		</Carousel>
		</Row>)
		}
		</>	
	}
	
	</Row>)
}