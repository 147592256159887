import React from 'react';
import './form.css';

export const TerminosCondiciones = (props) => {

    

    return (
        <div className={`cardTermsCondition p-2 `} >
            <div className="callme-message mb-2">
                <small className={`fw-bold  justify-content-between`}>
                <button className='position-absolute top-5 end-0 mt-2 me-2 border-0 bg-transparent fs-4' onClick={() => props.hideModal(false)}>✖</button>
                    <span className="center p-2">Términos y condiciones</span>
                </small>
            </div>
            <div className="justify-content">
                <span className="center p-2">
                    El presente documento establece los términos y condiciones generales (en adelante, los "Términos
                    y Condiciones”) aplicables al uso del sitio web www.distrimovil.pe (en adelante, el "Sitio”) puesto
                    a disposición del público en general (en adelante, indistintamente, el "Usuario” o los "Usuarios”) por parte
                    de Konecta BTO Perú (en adelante, la "Empresa”) para la comercialización de productos y
                    servicios prestados por América Móvil Perú S.A.C. (en adelante, "CLARO”). El Usuario que desee usar el
                    Sitio podrá hacerlo sujetándose a los Términos y Condiciones. LOS PRESENTES TÉRMINOS Y
                    CONDICIONES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, POR LO QUE CUALQUIER USUARIO
                    QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO.
                </span>
            </div>
            <div className="justify-content">
                <span className="center p-2">
                    El Usuario debe leer todas las condiciones establecidas en estos Términos y Condiciones, así como en los
                    demás documentos incorporados en el Sitio previo al uso del Sitio. La aceptación expresa de los Términos
                    y Condiciones que se establecen a continuación y/o el mero uso del Sitio que haga el Usuario, implica que
                    el Usuario declara haber sido previamente informado de, así como haber leído, comprendido y aceptado,
                    estos Términos y Condiciones, así como las condiciones particulares adhiere plenamente y sin reservas.
                </span>
            </div>
            <div className="justify-content">
                <span className="center p-2">
                    El Usuario declara y garantiza estar apto y tener capacidad legal para aceptar los presentes Términos y
                    Condiciones y que toda la información que proporcione a la Empresa en el marco del Sitio, bajo cualquier
                    forma, será exacta, verdadera, completa y correcta; liberando de cualquier responsabilidad sobre el
                    particular a la Empresa y a CLARO. En ese sentido, para efectos de las exoneraciones de responsabilidad,
                    CLARO comprende también a cualquiera de sus respectivos directores, funcionarios, empleados u otros
                    representantes.
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>1. Responsabilidad limitada.</h4>
                <span className="center p-2">
                    El Usuario declara haber comprendido y aceptado que toda información, comunicación, opinión,
                    manifestación, contenido, transacción u operación a la que acceda a través del Sitio es de exclusiva
                    responsabilidad de la Empresa; reconociendo el Usuario que el uso del Sitio y la que asigne al mismo son
                    bajo su propio riesgo. En ese sentido, el Usuario declara haber comprendido y aceptado que, por ser el
                    Sitio un espacio de propiedad de la Empresa y administrado exclusivamente por ella, CLARO no respalda
                    ni garantiza la información, comunicaciones, opiniones, manifestaciones, contenido, transacciones u
                    operaciones a las que el Usuario acceda a través del Sitio; por lo cual el Usuario libera de cualquier
                    responsabilidad sobre el particular a CLARO
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>2. Limitaciones y modificación de las condiciones aplicables al Sitio.</h4>
                <span className="center p-2">
                La Empresa expresamente se reserva la facultad y puede efectuar, en cualquier momento, bajo su
                exclusiva discreción y sin previo aviso, sobre estos Términos y Condiciones. Las alguna al Usuario. En los
                casos en que la Empresa estime conveniente realizar alguna ésta se efectuará por cualquier medio
                incluyendo, sin limitaciones, anotación en el sitio web www.distrimovil.pe, correo electrónico u
                otros mecanismos electrónicos o convencionales de correos o comunicaciones, o cualquier otro método
                equivalente. Es recomendable que el Usuario revise en forma periódica el Sitio a de informarse de las
                introducido; la continuación en el uso del Sitio, implicará la aceptación sin reservas a dichas
                modificaciones por parte del Usuario
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>3. Suspensión</h4>
                <span className="center p-2">
                La Empresa se reserva el derecho de suspender en cualquier momento, temporalmente y sin previo aviso,
                el Sitio, por el tiempo que resulte necesario. Ello incluye la eventual necesidad de efectuar operaciones de
                mantenimiento, reparación, actualización o mejora de las mismas o causas similares.
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>4. Fuerza mayor</h4>
                <span className="center p-2">
                La Empresa no garantiza la inexistencia de interrupciones o errores en el acceso al Sitio, ni será
                responsable de los retrasos o fallos que se produjeran en el acceso, funcionamiento y operatividad del
                Sitio, sus servicios y/o contenidos, así como tampoco de las interrupciones, suspensiones o el mal
                funcionamiento del mismo, cuando tuvieren su origen en fallas producidas por causas ajenas a su
                voluntad o fuera de su control o por cualquier otra situación de caso fortuito o fuerza mayor
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>5. Derechos de autor y marca</h4>
                <span className="center p-2">
                Empresa, y en lo que corresponda, a los derechos de CLARO, protegidos por las leyes de la propiedad
                intelectual e industrial y derechos de autor. En ningún caso el acceso a este Sitio implica ningún tipo de
                renuncia, transmisión o cesión total ni parcial de dichos derechos, ni confiere ningún derecho de
                utilización, alteración, explotación, reproducción, distribución o comunicación pública sobre dichos
                Empresa o de CLARO o del tercero titular de los derechos afectados. En función de lo anterior y sin
                perjuicio de las demás obligaciones que emanan del reconocimiento de los derechos de la Empresa o de
                CLARO protegidos por las leyes de la propiedad intelectual e industrial y derechos de autor, el Usuario está
                prohibido de transmitir, distribuir, reproducir, publicitar bajo cualquier modalidad o en general utilizar los
                contenidos, marcas, textos e imágenes gráficas de este Sitio para fines comerciales; modificar o utilizar la
                información sin la autorización previa, expresa y escrita de la Empresa o CLARO; o, transferir los terceros
                de manera expresa respecto de la titularidad de la Empresa o CLARO de los derechos de autor y
                prohibiciones antes mencionadas son a título enunciativo y no taxativo, por lo que se extenderán a todas
                aquellas que se deriven del respeto y reconocimiento de los derechos de la Empresa o de Claro protegidos
                por las leyes de la propiedad intelectual e industrial y derechos de autor.
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>6. Uso del sitio bajo responsabilidad</h4>
                <span className="center p-2">
                El uso de este Sitio o de cualquier área del mismo o de todo o parte de la información o contenidos
                incorporados en el mismo, o el solicitar cualquier producto o servicio mediante este Sitio, o el aprobar
                cualquier material o información, se hace bajo la única y exclusiva responsabilidad del Usuario que
                accede al mismo. La Empresa no garantiza que el uso del Sitio será ininterrumpido y sin posibilidad de
                errores. La Empresa no asume responsabilidad alguna derivada de los contenidos de los enlaces de
                terceros que se puedan encontrar en el Sitio, ni garantiza la ausencia de virus u otros elementos en los
                mismos que puedan producir alteraciones o daños en el sistema informático del Usuario (hardware y/o
                software) o sus archivos o ficheros.
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>7. Vigencia, interrupción y terminación del Sitio</h4>
                <span className="center p-2">
                Interrumpir, desactivar, denegar, bloquear, dar por terminado el acceso o cancelar, en todo o parte, el Sitio,
                por cualquier razón, incluyendo, sin limitación, la detección de cualquier tipo de acto u omisión que, a
                juicio y a sola discreción de la Empresa, se considere como una violación a estos Términos y Condiciones,
                a otras condiciones aplicables o a las que emanen de la normativa vigente, sin que ello genere para La
                Empresa responsabilidad de clase alguna.
                La Empresa no asumirá responsabilidad alguna frente al Usuario o frente a terceros por ninguna de las
                acciones antes mencionadas. Ni la interrupción, desactivación, denegación, bloqueo, terminación del
                acceso, desactivación del Sitio ni cualquier acción u omisión del Usuario implicará la pérdida de vigencia
                de estos Términos y Condiciones.
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>8. Interpretación</h4>
                <span className="center p-2">
                Los títulos de las cláusulas o numerales son meramente orientadores y no forman parte del contenido
                preceptivo de estos Términos y Condiciones. Los términos y condiciones que integran los presentes
                partes, debiendo interpretarse los unos por los otros atribuyendo a los dudosos el sentido que se derive
                del Cualesquiera documentos o declaraciones previos a la entrada en vigor de los presentes Términos y
                Condiciones. Cualquier documento o declaraciones previos a la entrada en vigor de los presentes
                Términos y Condiciones se considerán definitivamente derogados.
                </span>
            </div>
            <div className="justify-content mb-2">
                <h4 className='fs-6 fw-bold ps-2'>9. Competencia</h4>
                <span className="center p-2">
                Todas las cuestiones relacionadas al uso del Sitio se rigen por las leyes peruanas. La Empresa y el
                Usuario hacen renuncia expresa a cualquier otro fuero y convienen que cualquier controversia derivada de
                su uso o aplicación será sometida a la jurisdicción de los Juzgados y Tribunales competentes de Lima,
                Perú. En el caso de que el Usuario tenga su domicilio fuera de Perú, la Empresa y el Usuario se someten,
                con renuncia expresa a cualquier otro fuero, a los juzgados y tribunales competentes de Lima, Perú.
                </span>
            </div>
        </div>
    )
}