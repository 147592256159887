import React,{useState, useRef,useEffect} from 'react';
//import img from './../../assets/img/d.svg';
import img from './../../assets/img/Insignia-1.png';
import { Row } from 'react-bootstrap';
import phoneIcon from './../../assets/img/icons/phone-icon.png';
import { useLocation } from 'react-router-dom';
import './header.css';
import { useWebService } from './../../hooks/useWebService';


export const MainNavbar = ({setHeight}) => {
	const headerRef = useRef()

	

	const[viewCall,setViewCall] = useState(false);	
	const navegador = navigator.userAgent;
	useEffect(()=>{
		if(navegador.match(/Mobile/i)){
			setViewCall(true);
		}
	})
	const [toggle, setToggle] = useState(false);
	const [matches, setMaches] = useState(window.matchMedia("(min-width: 864px)").matches)
	const onMenuClick = (e) => setToggle(!toggle);
	const {pathname} = useLocation();
	const [toggleLink, setToggleLink] = useState({
		planes_hogares : false,
		planes_moviles : false,
	})

	const {landingInfo } = useWebService();

	const handleChangePage = () => {
		setToggle(false);
		window.scrollTo(0,0);
	}
	const handleClickLink = (e,link) => setToggleLink({planes_hogares: false,planes_moviles:false,[link] : !toggleLink[link]})

	const phoneNumber = {
		show : landingInfo.numero_llamada_landing,
		value: landingInfo.numero_llamada_landing.split("-").reduce( (p,c) => p+=c,""),
	}

	useEffect(() => {
		window
		.matchMedia("(min-width:864px)")
		.addEventListener("change",e => setMaches(e.matches))
		setHeight(headerRef?.current?.clientHeight??0);
	},[headerRef?.current?.clientHeight,setHeight]);

	useEffect(() => {
		setToggle(matches)
	},[matches])
	return (
		<header className="mainnavbar" ref={headerRef}>
			<div className="">
				<Row className="container mx-auto top-bar">
					<div className="d-flex justify-content-between col-lg-6 h-100">
						<div className="center">
							<img src={img} alt="" width="215" height="40px"/>
						</div>
						{/*<div className={`menu-icon pt-3 ${toggle && "active"}`} onClick={onMenuClick}>
							<span></span>
							<span></span>
							<span></span>
						</div>*/}
					</div>
					<div className="text-white col-12 col-md-6">
						<p className="text-center text-lg-end fw-normal llama-sm">
							Llámanos gratis al <a href={`tel:${phoneNumber.value}`} className="text-white text-decoration-none fw-bold fs-4">
								{phoneNumber.show}
							</a>
						</p>
						<div className="llama-md">
							<div className="d-flex mb-3 justify-content-end pt-2">
								<div className="text-end center-y justify-content-end me-2">
									<span>Llama gratis al</span>	
								</div>
								<div className="d-flex justify-content-end">
									<div className="center me-2">
										<img src={phoneIcon} alt="telefono" className="invert-color" width="35"/>
									</div>
									<div className="lh-sm d-flex flex-column pt-2">
										<small className="fw-light d-inline-block fs-sm lh-1">LÍNEA EXCLUSIVA DE VENTAS</small>
										{ viewCall?
										<a href={`tel:${phoneNumber.value}`} className="text-decoration-none text-light">
											<b className="fs-4">
												{phoneNumber.show}
											</b>
										</a>
										:
											<b className="fs-4">
												{phoneNumber.show}
											</b>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Row>
				<Row>
					<div className="bg-dark-red">
						<div className={`container navbar-container ${!toggle && "active"}`} style={{height: `calc(100vh - ${headerRef.current?.clientHeight??0}px)`, top: `${headerRef.current?.clientHeight??0}px`}}>
							<nav className="navbar-links text-white">
								{/*<div className="link-parent">
									<span className={`link ${pathname.includes("hogar") && "active"} `} onClick={(e) => handleClickLink(e,"planes_hogares")}>
										Planes Hogares
									</span>
									<ul className={`navbar-list ${!toggleLink.planes_hogares && "d-none"}`}>
										<li className="link">
											<NavLink to="/hogar/netflix" className={({isActive}) => `${isActive && "active"}`} onClick={handleChangePage}>
												Paquetes Netflix
											</NavLink>
										</li>
										<li className="link">
											<NavLink to="/hogar/dosplay" className={({isActive}) => `${isActive && "active"}`} onClick={handleChangePage}>
												Paquetes 2Play
											</NavLink>
										</li>
										<li className="link">
											<NavLink to="/hogar/tresplay" className={({isActive}) => `${isActive && "active"}`} onClick={handleChangePage}>
												Paquetes 3Play
											</NavLink>
										</li>
									</ul>
								</div>
								<div className="link-parent">
									<span className={`link ${pathname.includes("moviles") && "active"}`} onClick={(e) => handleClickLink(e,"planes_moviles")}>
										Planes Móviles
									</span>
									<ul className={`navbar-list ${!toggleLink.planes_moviles && "d-none"}`}>
										<li className="link">
											<NavLink to="/moviles" onClick={handleChangePage}>
												Paquetes Moviles
											</NavLink>
										</li>
									</ul>
								</div>*/}
							</nav>	
							{/*<div className="navbar-footer">
								<div className="text-white">
									<p className="text-center fw-bold">
										Llama gratis al
										<br/>
										<a href="tel:016806508" className="text-white text-decoration-none fw-bold fs-3">01-6806508</a>
									</p>
								</div>
								<img src={img} alt="" width="100%"/>
							</div>
							*/}
						</div>
					</div>
				</Row>
			</div>
		</header>
	);	
}