import React,{useState} from 'react';
import { Form } from 'react-bootstrap';

export const InputNumber = (props) => {

    const [number,setNumber] = useState("");

    const handleNumberChange = (e) => {
        const {value} = e.target;
        if(!isNaN(value)){
            setNumber(value);
        }
        if(props.onChange){
            props.onChange(e);
        }
    }
    return(<Form.Control 
                className={`${props.className}`}
                name={props.name} 
                placeholder={props.placeholder}
                required 
                maxLength={props.maxLength}
                value={number}
                {...props}
                onChange={handleNumberChange}/>
    )
}