import React from 'react';

export const NotFound = ({height}) => {

	const styles = {
		title : {
			fontSize : "13rem",
		},
		desc : {
			fontSize : "5rem",
		}
	}

	return <div style={{marginTop: (height + 5) + "px"}}>
		<h1 className="text-center" style={styles.title}>404</h1>
		<p className="text-center" style={styles.desc}>Not Found</p>
	</div>
}