import React from 'react';
import { Modal } from 'react-bootstrap';

export const CustomModal = ({bodyModal,setShow,show,size}) => {
	const handleClose = () => setShow(false);
	return (
		<>
			<Modal show={show} onHide={handleClose} size={size}>
				<Modal.Body className="p-0">
					{bodyModal}
				</Modal.Body>
			</Modal>
		</>
	);

}