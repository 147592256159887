import { useState } from 'react';
import ModalContext from './ModalContext';
import { CustomModal } from './../../components/CustomModal'
export const ModalState = ({children}) => {
	const [show,setShow] = useState(false);
	const [body,setBody] = useState(null);
	const [modalSize,setModalSize] = useState("md");
	return (
		<ModalContext.Provider value={{setShow,setBody,setModalSize}}>
			<CustomModal setShow={setShow} show={show} bodyModal={body} size={modalSize}/>
			{children}
		</ModalContext.Provider>
	)
}