import React from 'react';
import { Banner } from './../../components/Banner';
import { FormCallme } from './../../components/Form';
import { PlanesMoviles } from './../../components/Planes';
import { Contact } from './../../components/Contact';
import { Footer } from './../../components/Footer';
import { useWebService} from './../../hooks/useWebService';
export const Moviles = ({height}) => {

	const {banners,footerItems} = useWebService();
	
	const images = banners.filter(b => b.activo === 1 && b.tipo === "moviles");

	const items = footerItems.filter(b => b.activo === 1 && b.tipo === "moviles");
	return <>
		<Banner height={height} images={images}/>
		<FormCallme/>
		<PlanesMoviles/>
		<Contact/>
		<Footer title="¿Por qué elegir MAX?" footerItems={items}/>
	</>;
}