import { useState,useEffect } from 'react';
import phoneIcon from './../../assets/img/icons/phone-icon.png';
import { Row, Col, InputGroup, Form, Button, Spinner } from 'react-bootstrap'
import { FooterItem } from './FooterItem';
import './footer.css';
import { Autorizo } from './../Form/Autorizo';
import { enviarInformacion } from './../../services/Contactanos';
import { useWebService, useModal } from './../../hooks';
import { TerminosCondiciones } from './../Form/terminosCondiciones';
import { Image } from '../Media/Image';


const informacion_beneficions = [
	{
		imagen : "claro_video_footer.png",
		text : "Con un Plan Max  o  Max Ilimitado, podrá acceder  a  la  sección Catálogo gratis por 24 meses y vincularlo hasta en 5 dispositivos",
	},
	{
		imagen : "claro_musica_footer.png",
		text : "Además, podrá disfrutar de Claro Música gratuito y escuchar todas las canciones de principio a fin en modo aleatorio.",
	},
	{
		imagen : "mi_claro_footer.png",
		text : "A través de la App Mi Claro, tu cliente podrá hacer todas estas operaciones: Revisar y pagar recibos, consulta saldos, comprar paquetes y más!",
	},
	{
		imagen : "claro_club_footer.png",
		text : "¡Todos  los  clientes Claro son  parte  del  Club!. Recuérdale  a  tu  cliente que con la APP Claro Club disfruta de muchos beneficios a nivel nacional. Podrá canjear  descuentos en establecimientos de comida, entretenimiento, educación y mucho más.",
	},
	{
		imagen : "claro_drive_footer.png",
		text : "Claro drive es el servicio de almacenamiento en la nube que le permitirá a tu cliente liberar espacio en un Smartphone, PC o LapTop y acceder a ellos desde donde esté. Tendrá 25 GB GRATIS ",
	},
]

export const Footer = ({ title, footerItems, center = false }) => {
	
	const { landingInfo } = useWebService();
	const { setBody, setShow,setModalSize } = useModal();
	const [isLoading, setIsLoading] = useState(false);
	const phoneNumber = {
		show: landingInfo.numero_llamada_landing,
		value: landingInfo.numero_llamada_landing.split("-").reduce((p, c) => p += c, ""),
	}


	const[viewCall,setViewCall] = useState(false);	
	const navegador = navigator.userAgent;
	useEffect(()=>{
		if(navegador.match(/Mobile/i)){
			setViewCall(true);
		}
	})

	const solicitudEnviada = () => (<div>
		<div className="p-3 line-height-1 text-center">
			<h2 className="fs-2 mb-4 text-red">¡Gracias por contactarnos!</h2>
			<p className="mb-4">Ya tenemos tu email</p>
			<p className="text-muted fs-sm-1">Nuestros horarios de atención son de Lunes a Viernes de 8am a 9pm y sábado de 8am a 5pm</p>
		</div>
	</div>)
	const handleSubmit = async e => {
		e.preventDefault();
		const data = new FormData(e.target);
		const { correo } = Object.fromEntries(data.entries());
		setIsLoading(true);
		await enviarInformacion({ "email": correo, "tipo": 0 }, "correo")
		setIsLoading(false);
		setBody(solicitudEnviada());
		setShow(true);
	}

	const openTerminosCondiciones = () => {
		setBody(<TerminosCondiciones  hideModal={setShow}/>);
		setShow(true);
		setModalSize("xl");
	}

	return (
		<footer>
			
			<div className='bg-dark-red pb-4'>
				<div className='container'>
					<h3 className='text-white text-center py-4 fs-5'>RECUERDA QUE ESTOS BENEFICIOS SON EXCLUSIVOS DE CLARO:</h3>
					<Row>
					{informacion_beneficions.map( (beneficio,i) => {
						return <Col xs="12" md='6' className='p-1'>
							<div className='bg-white p-2 mb-2 rounded-2 d-flex align-items-center h-100'>
								<Image src={`img/icons_footer/${beneficio.imagen}`} width={100} className='d-inline-block me-4'/>
								<p style={{fontSize : "14px"}}>{beneficio.text}</p>
							</div>
						</Col>
					})}
					</Row>
				</div>
			</div>
			<section className="my-5">
				{/*
				<div className="container-email mb-5">
					<h2 className="text-center fs-3 fw-bold consideraciones-title mb-5">¡Descubre las ofertas que tenemos para ti!</h2>
					
					<Form onSubmit={handleSubmit}>
						<Row>
							<div className="col-12 col-lg-6">
								<InputGroup className="mb-3">
									<InputGroup.Text className="prepend-custom">@</InputGroup.Text>
									<Form.Control className="form-control-custom" name="correo" placeholder="Deja aquí tu correo electrónico" required />
								</InputGroup>
							</div>
							<div className="mb-2 col-12 col-lg-6">
								<Button type="submit" variant="claro" className="btn-email" disabled={isLoading}>
									{isLoading &&
										<Spinner size="sm" />
									}
									¡Envíame más información!
								</Button>
							</div>
						</Row>
						<Autorizo />
					</Form>

				</div>
				*/}
			</section>
			<div className="bg-footer-info">
				<Row className="container m-auto">
					{/*<Col className="d-none d-lg-block">
						<h4 className="fs-6 fw-bold mb-4">SERVICIOS HOGAR</h4>
						<ul className="list-unstyled">
							<li className="text-decoration-underline-hover mb-3">Paquete Netflix</li>
							<li className="text-decoration-underline-hover mb-3">Paquete 2Play</li>
							<li className="text-decoration-underline-hover mb-3">Paquete 3Play</li>
						</ul>
					</Col>
					
					<Col className="d-none d-lg-block">
						<h4 className="fs-6 fw-bold mb-4">SERVICIOS MÓVILES</h4>
						<ul className="list-unstyled">
							<li className="text-decoration-underline-hover">Paquetes Móviles</li>
						</ul>
					</Col>
					*/}
					<Col className="text-end" xs="12" md="6">
						<div className="d-flex mb-3 ">
							<div className="text-end  justify-content-end me-2">
								<span>Llama gratis al</span>
							</div>
							
							<div className="d-flex justify-content-end">
								<div >
									<img src={phoneIcon} alt="telefono" width="35" />
								</div>
								<div className="lh-sm d-flex flex-column">
									<small className="fw-light text-center d-inline-block">Línea exclusiva de ventas</small>
									{viewCall?
									<a href={`tel:${phoneNumber.value}`} className="text-decoration-none text-dark">
										<b className="fs-4">
											{phoneNumber.show}
										</b>
									</a>
									:
									<b className="fs-4">
										{phoneNumber.show}
									</b>
									}
								</div>
							</div>

						</div>


					</Col>
					<Col xs="12" md="6">
						<div className="text-end">
							<p className="m-0 fw-bold">{landingInfo.direccion_landing}</p>
							<p className="m-0">Lima</p>
							<p className="m-0">Ruc: {landingInfo.ruc_landing}</p>
							<p className="m-0 text-decoration-underline" onClick={openTerminosCondiciones}>Terminos y condiciones</p>
							{/* <p className="m-0 text-decoration-underline" onClick={openTerminosCondiciones}>Política de tratamiento de datos</p> */}
						</div>
					</Col>
				</Row>
			</div>
			{/*
			<div className="bg-footer-down">
				<Row>
					<div className="col-12 col-lg-6 desc order-lg-2 mx-auto">
						<p>
							Servicios sujetos a disponibilidad de cobertura y viabilidad técnica. Conoce condiciones y restricciones en
							<br />
							<a href="https://www.claro.com.pe/personas/hogar/consideraciones-planes-hfc/" target="_blank" rel="noreferrer">https://www.claro.com.pe/personas/hogar/consideraciones-planes-hfc/</a>
						</p>
					</div>
				</Row>
			</div>
			 */}
		</footer>
	)
}