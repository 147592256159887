import { API_URL } from "../../constants/urls";

/*import planesHogarMoviles from './mock_db_moviles.json';
import planesHogarDosPlay from './mock_db_dos_play.json';
import planesHogarTresPlay from './mock_db_tres_play.json';
import planesHogarNetflix from './mock_db_netflix.json';*/
export const listarPaquetes = async (plan) => {
	//const url = API_AWS + "/ClaroLandingAPI";

	const API_AWS = API_URL.API_AWS;

	const url = API_AWS;
	const requestBody = {
		"option": "Paquetes",
		"datos": {
			"value": plan
		}
	}
	const request = await fetch(url,{
		method : "POST",
		body : JSON.stringify(requestBody),
	}).then(resp => {
		if(resp.ok) return resp.json()

		//throw new Error("Peticion incorrecta")
		throw new Error("P.I")
	})
	.then(json => json)
	.catch(console.log)
	return request.filter(r => !!r)??[];
}
export const listarPlanesMoviles = async () => {

	let planesFiltered = {
		max : [],
		max_ilimitado : [],
		max_play_netflix : []
	};
	const response = await listarPaquetes("Moviles");
	response.map(p => planesFiltered[p.plan] = [...planesFiltered[p.plan],p])
	//await planesHogarMoviles.map(p => planesFiltered[p.plan] = [...planesFiltered[p.plan],p])
	planesFiltered = sortByPrice(planesFiltered);
	return planesFiltered;
}
const sortByPrice = (array) => {
	const keys = Object.keys(array)
	for(const key of keys){
		array[key] = array[key].sort((a,b) => parseFloat(a.precio) - parseFloat(b.precio))
	}
	return array;
}

export const listarPlanesHogarDosPlay = async () => {

	let planesFiltered = {
		"dos_play_internet_telefonia" : [],
		"dos_play_internet_television" : [],
		"dos_play_fibra_internet_telefonia" : [],
		"dos_play_fibra_internet_television" : [],
	}
	const response = await listarPaquetes("2Play");
	response.map(p => planesFiltered[p.plan] = [...planesFiltered[p.plan],p])
	planesFiltered = sortByPrice(planesFiltered);
	return planesFiltered;
}

export const listarPlanesHogarTresPlay = async() => {

	let planesFiltered = {
		"tres_play" : [],
		"tres_play_fibra_optica" : [],
	}
	const response = await listarPaquetes("3Play");
	response.map(p => planesFiltered[p.plan] = [...planesFiltered[p.plan],p])
	//planesHogarTresPlay.map(p => planesFiltered[p.plan] = [...planesFiltered[p.plan],p])
	planesFiltered = sortByPrice(planesFiltered);
	return planesFiltered;
}
export const listarPlanesHogarNetflix = async() => {
	let planesFiltered = {
		"netflix" : [],
		"max_play_netflix" : [],
	}
	const response = await listarPaquetes("Netflix");
	response.map(p => planesFiltered[p.plan] = [...planesFiltered[p.plan],p])
	//planesHogarNetflix.map(p => planesFiltered[p.plan] = [...planesFiltered[p.plan],p])
	planesFiltered = sortByPrice(planesFiltered);
	return planesFiltered;
}
