//import { API_AWS, API_KONECTA } from "../../constants/urls";
import { API_URL} from "../../constants/urls";

export const enviarInformacion = async (valor,tipo,uuid_plan = null,plan) => {
	
	const API_AWS = API_URL.API_AWS;
	const API_KONECTA  = API_URL.API_KONECTA;

	const url = API_KONECTA ;

	const DatosRequestBody = {
		
			"plan_interes" : uuid_plan,
			"tipo" : tipo,
			"valor" : valor,
			"plan" : plan,
			"uuid" : uuid_plan
		
	}

	let datos= "8iLC"+window.btoa(JSON.stringify(DatosRequestBody))+"WxlZ"; // encriptamos la data que vamos a enviar con btoa, para desencriptar usamos atob
	const requestBody = {
		"option" : "enviar_informacion",
		datos
	}

	const  requestBodyCryp = JSON.stringify(requestBody);
	//let prueba = window.atob(datos);
	//console.log(prueba);

	const request = await fetch(url,{
		method: "POST",
		//body : JSON.stringify(requestBody),
		body : requestBodyCryp,
 		headers : {
            "Content-Type" : "application/json",
        },
        mode : 'no-cors',
	}).then(resp => resp.json())
	.then(json => {
		if(json.status === 200){
			return {
				"message" : "Se envío la información",
				"status" : true,	
			}
		}
		//throw new Error("Error al enviar")
		throw new Error("E.E")
	}).catch(err => {
		return {
			"message" : err.message,
			"status" : false,
		}
	})
	const urlAWS = API_AWS;
	fetch(urlAWS,{
		method: "POST",
		//body : JSON.stringify(requestBody),
		body : requestBodyCryp,
 		headers : {
            "Content-Type" : "application/json",
        },
        mode : 'no-cors',
	}).then(resp => resp.json())
	.then(json => {
		if(json.status === 200){
			return {
				"message" : "Se envío la información",
				"status" : true,	
			}
		}
		//throw new Error("Error al enviar")
		throw new Error("E.E")
	}).catch(err => {
		return {
			"message" : err.message,
			"status" : false,
		}
	})
	return request
}