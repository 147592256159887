import { useState } from 'react';
import WebServiceContext from './WebServiceContext';
export const WebServiceState = ({children}) => {

	const [isLoading,setIsLoading] = useState(false);
	const [planesDosPlay, setPlanesDosPlay] = useState({
		"dos_play_internet_telefonia" : [],
		"dos_play_internet_television" : [],
		"dos_play_fibra_internet_telefonia" : [],
		"dos_play_fibra_internet_television" : [],
	});
	const [planesNetflix, setPlanesNetflix] = useState({
		"netflix" : [],
		"max_play_netflix" : [],
	});
	const [planesTresPlay, setPlanesTresPlay] = useState({
		"tres_play" : [],
		"tres_play_fibra_optica" : [],
	});
	const [planesMoviles, setPlanesMoviles] = useState({
		max : [],
		max_ilimitado : [],
		max_play_netflix : []
	});

	const [banners,setBanners] = useState([]);
	const [footerItems,setFooterItems] = useState([])

	//Default, Lo demas se carga en App.js => useEffect
	const [landingInfo,setLandingInfo] = useState({
        "numero_llamada_landing": "01-6322849",
        "ruc_landing": "20546992986",
        "direccion_landing": "JR. CARABAYA NRO. 933 CERCADO DE LIMA (PISO 1 Y 8- OFIC. 26,27 Y 28)",
        "mensaje_formulario_hogar": "Obtén el 50% descuento hasta x 3 meses",
        "mensaje_formulario_movil": "Cámbiate a Claro Móvil y obtén 20% de dto. x 3 meses en tu plan postpago",
        "autorizo_mensaje": "Autorizo a Konecta BTO Perú – Sucursal con domicilio en Jr. Carabaya N° 933 Cercado de Lima (Piso 1 y 8- Ofic. 26,27 Y 28), Lima, Perú (en adelante, Konecta BTO), para tratar mis datos personales y registrarlos en el Banco de Datos Personales “Usuarios Web”, con la finalidad de ser contactado para recibir información y acceder a la promoción de productos y/o servicios que he solicitado mediante este formulario. Se me informa que (i) mis datos serán registrados por el tiempo necesario para cumplir con la finalidad expuesta, o hasta que decida revocar mi autorización, (ii) Konecta BTO podrá compartir mis datos con AMERICA MOVIL PERU SAC, Perú, con domicilio en Av. Nicolas Arriola Nro. 480, Santa Catalina, La Victoria, Lima, Perú para los fines descritos arriba (iii) mi autorización resulta facultativa, pero en caso de negativa, la finalidad expuesta no podrá realizarse, (iii) podré ejercer derechos contenidos en la Ley 29733, enviando una comunicación a la dirección Jr. Carabaya N° 933 Cercado de Lima (Piso 1 y 8- Ofic. 26,27 Y 28), Lima, Perú."
    });
	return (
		<WebServiceContext.Provider 
			value={{
				planesDosPlay,
				setPlanesDosPlay,
				planesNetflix,
				setPlanesNetflix,
				planesTresPlay,
				setPlanesTresPlay,
				planesMoviles,
				setPlanesMoviles,
				banners,
				setBanners,
				footerItems,
				setFooterItems,
				landingInfo,
				setLandingInfo,
				isLoading,
				setIsLoading,
			}}
			>
			{children}
		</WebServiceContext.Provider>
	)
}