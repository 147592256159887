import React,{useEffect,useState} from 'react';
import './form.css';
import { Button, Spinner } from 'react-bootstrap';
import {Autorizo} from './Autorizo';
import { useModal } from './../../hooks/useModal';
import { enviarInformacion } from './../../services/Contactanos';
import phoneIcon from './../../assets/img/icons/icon-phone.png';
import { useLocation} from 'react-router-dom';
import { useWebService } from './../../hooks/useWebService';
import { InputNumber } from '../Fields/InputNumber';
export const FormCallme = ({isModal = false,plan = null}) => {

	const {setShow,setBody} = useModal();
	const {pathname} = useLocation();
	const [scrollHeight,setScrollHeight] = useState(0)
	const [enviado,setEnviado] = useState(false);
	const [isLoading,setIsLoading] = useState(false);
	const {landingInfo} = useWebService();
	const [hide,setHide] = useState(false);
	const phoneNumber = {
		show : landingInfo.numero_llamada_landing,
		value: landingInfo.numero_llamada_landing.split("-").reduce( (p,c) => p+=c,""),
	}

	const updateScroll = () => {
		return setScrollHeight(window.pageYOffset)
	}

	useEffect(() => {
		if(scrollHeight > 300){
			setHide(false);
			
		}
	},[scrollHeight,hide]);

	const solicitudEnviada = () => (<div>
		<div className="p-3 line-height-1">
			<h2 className="fs-1">¡Gracias por contactarnos!</h2>
			<p>En breve un asesor se pondrá en contacto contigo para resolver tus dudas</p>
			<p className="text-muted">Nuestros horarios de atención son de Lunes a Viernes de 8am a 9pm y sábado de 8am a 5pm</p>
		</div>
	</div>)
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true)
		
		const data = new FormData(e.target);
		const {numero} = Object.fromEntries(data.entries());
		await enviarInformacion({"telefono" : numero,"tipo" : 1},"telefono",plan?.id??"",plan?.plan??"")
		sessionStorage.setItem("enviado",true);
		//setEnviado(!!sessionStorage.getItem("enviado"));
		setShow(true)
		setBody(solicitudEnviada())
		setIsLoading(false);
	}
	
	const hideForm = () => {
		setHide(true);
	}

	useEffect(() => {
		//setEnviado(!!sessionStorage.getItem("enviado"));
		window.addEventListener('scroll', updateScroll);
	},[])

	return (
		// <div className={`section form-container ${!isModal && "p-fixed"} ${((scrollHeight >300 && !hide) || (hide && scrollHeight <= 300) ) && "hide"}`}>
		//<div className={`section form-container ${!isModal && "p-fixed"} ${(scrollHeight >300 || hide ) && "hide"}`}>
		<div className={`section form-container ${!isModal && "p-fixed"} ${(scrollHeight >300 || hide ) && "hide"}`}>
		{ enviado
			?<div className={`center text-center py-5 px-3 ${!isModal && "d-none"}`}>
				<h3 className="text-claro fw-bold fs-1">¡Muchas gracias!</h3>
				<p>Ya tenemos tus datos y en unos minutos nos pondremos en contacto contigo</p>
			</div>
			:<>{!isLoading
			?<><div className="callme-message mb-1">
				<small className={`fw-bold center-x ${isModal && "justify-content-between"}`}>
					{isModal && <><span className="center p-2">Llámanos al {phoneNumber.show}</span>
					<Button variant="transparent" className="text-light fw-bold" onClick={() => setShow(false)}>✖</Button>
					</>}
					
				</small>
			</div>
			<div className="container">
				{!isModal && <span className='position-relative pointer d-none d-md-inline' onClick={hideForm}>✖</span>}
				<p className="form-title text-center m-0">¿Te interesa?</p>
				<p className="fs-5 fw-bold text-center">¡Te llamamos gratis!</p>
			</div>

			<form className="form-callme" onSubmit={handleSubmit}>
				<div className="container">
					<InputNumber className="fs-form-input" 
								 placeholder="Nro de Tel, ej: 999999999/ fijo 01 6000000" 
								 name="numero"
								 maxLength="9"/>
					<small className="line-height-1 my-3 d-block fw-light fs-sm">Por favor, ingresa tu número con el código de área o con el indicativo de tu ciudad si te vamos a llamar a un fijo</small>
					<Button type="submit" variant="gradient" className="mb-2 d-flex justify-content-between center-y">
						<span>!Solicita llamada!</span>
						<img src={phoneIcon}alt=""/>
					</Button>
					<Autorizo/>
				</div>
			</form></>
			:<div className="center spinner-container">
				<Spinner className="fs-1"/>
			</div>
			}</>
		}
		</div>
	)	
}