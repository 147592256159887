import React,{useState} from 'react';
import toggleIcon from './../../assets/img/icons/toggle.svg';
import { Button, Form } from 'react-bootstrap';
import { useWebService } from './../../hooks/useWebService';
export const Autorizo = () => {

	const [toggle, setToggle] = useState(false);
	const {landingInfo} = useWebService();
	const onToggleClick = (e) => setToggle(!toggle)
	return (
	<div className="autorizo">
		<div className="d-flex center-y">
			<Form.Check required className="d-flex align-items-center" type="checkbox" size="sm" label={<small className="ms-3" >Autorizo el tratamiento de mis datos</small>}/>
			<Button variant="toggle" size="sm" className="ms-2" onClick={onToggleClick}>
				<img src={toggleIcon} alt=""/>
			</Button>	
		</div>
				
		{/* <p className={`autorizo-desc ${toggle && "active"} px-4`} 
			style={{fontSize: "11px"}}
			dangerouslySetInnerHTML={{__html: landingInfo.autorizo_mensaje}}></p> */}
		<p className={`autorizo-desc ${toggle && "active"} px-4`} 
			style={{fontSize: "11px"}}>
				Otorga su consentimiento a Konecta Perú con domicilio en Jr. Carabaya N° 933 Cercado de Lima (Piso 1 y 8- Ofic. 26,27 Y 28), Lima, Perú, para
				utilizar los datos personales que ha registrado, con la finalidad de recibir información sobre productos y/o
				servicios que ha solicitado mediante este formulario. Es oportuno mencionarle que sus datos personales
				serán registrados en nuestro Banco de Datos que ha sido declarado a la Autoridad Nacional de Protección
				de Datos Personales.
		</p>
		<p className={`autorizo-desc ${toggle && "active"} px-4`} 
			style={{fontSize: "11px"}}>
				Usted podrá revocar su consentimiento y/o ejercer los derechos ARCO (Acceso, Rectificación, Cancelación
				y Oposición); en Jr. Carabaya N° 933 Cercado de Lima (Piso 1 y 8- Ofic. 26,27 Y 28), Lima, Perú. Konecta
				garantiza por su parte, el respeto y observancia al ejercicio de dichos derechos.
		</p>

	</div>)
}