import React,{useState,useEffect} from 'react';
import './planes.css';
import { listarPlanesMoviles } from './../../services/Planes';
import { ListaPlanes} from './ListaPlanes';
import { useWebService } from './../../hooks/useWebService';
import { Spinner } from 'react-bootstrap';
export const PlanesMoviles = () => {
	const [isLoading,setIsLoading] = useState(false);
	const {planesMoviles,setPlanesMoviles} = useWebService();
	useEffect(() => {
		const request = async () => {
			if(planesMoviles.max.length === 0){
				setIsLoading(true);
				const response = await listarPlanesMoviles()
										.finally(() => setIsLoading(false));;
				setPlanesMoviles(response);
			}
		}
		request();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	return <section className="section container">
		<div className="px-2">
			{isLoading
			?<div className="center spinner-container"><Spinner/></div>
			:<div>
				<ListaPlanes title="Elige tu Plan Max Ilimitado"
							desc={<span>Tenemos <strong className='text-claro'>Planes ilimitados</strong> desde S/. 69.90. Con Claro tienes Cobertura Internacional (Datos y Minutos), en nuestros Planes.
							Con <strong className='text-claro'>Full Claro</strong> Recibes hasta <strong className='text-claro'>50%</strong> más de GB en tu <strong className='text-claro'>celular</strong> y el <strong className='text-claro'>100%</strong> más de velocidad en tu <strong className='text-claro'>Servicio Hogar</strong></span>}
							planes={planesMoviles.max_ilimitado}/>
				<ListaPlanes title="Conoce nuestros planes móviles y elige tu Plan Postpago Max"
							desc={""}
							planes={planesMoviles.max}/>
				<ListaPlanes title="Elige tu Plan móvil Max Play con Netflix"
							desc="Ahora la suscripción a Netflix viene incluida en los nuevos planes Max Play"
							planes={planesMoviles.max_play_netflix}/>
			</div>
			}
		</div>
	</section>
}