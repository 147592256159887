import React,{useState,useEffect} from 'react';
import phone from './../../assets/img/icons/icon-phone.png';
import whatsapp from './../../assets/img/icons/icon-whatsapp.svg';
import './contact.css';
import { Form, Button } from 'react-bootstrap';
import { MiniForm} from './MiniForm';
import { enviarInformacion } from './../../services/Contactanos';
import { useWebService } from './../../hooks/useWebService';
import { InputNumber} from './../Fields/InputNumber';
export const Contact = () => {

	const [toggleEncuesta,setToggleEncuesta] = useState(false);
	const [toggleForm, setToggleForm] = useState(false);
	const [toggleMessage, setToggleMessage] = useState(false);
	const {landingInfo } = useWebService();
	const phoneNumber = {
		show : landingInfo.numero_llamada_landing,
		value: landingInfo.numero_llamada_landing.split("-").reduce( (p,c) => p+=c,""),
	}

	const [data,setData] = useState({
		"telefono" : "",
		"email" : "",
		"tipo" : 2
	})

	const handleOpenForm = () => {
		setToggleForm(true);
		setToggleEncuesta(false);
	}

	const handleChange = (e) => {
		const {name,value} = e.target;
		setData({...data,[name] : value})	
		console.log(data);
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		await enviarInformacion(data,"telefono")
		setToggleMessage(true);
		setToggleForm(false);
	}

	const[viewCall,setViewCall] = useState(false);	
	const navegador = navigator.userAgent;
	useEffect(()=>{
		if(navegador.match(/Mobile/i)){
			setViewCall(true);
		}
	})

	return <div className="contact-container">
		{viewCall?
		<div className="contact-link phone btn btn-gradient">
			<a href={`tel:${phoneNumber.value}`}>
				<img src={phone} className="bg-red" alt=""/>
			</a>
		</div>
		:""
		}
		{/*<div className="contact-link whatsapp">
			<a href="https://api.whatsapp.com/send?phone=51987738077">
				<img src={whatsapp} className="bg-green" alt=""/>
			</a>
</div>*/}
		{/*
		<div className="encuesta" onClick={() => setToggleEncuesta(true)}>
			<span>?</span>
		</div>
		<MiniForm toggle={toggleEncuesta} setToggle={setToggleEncuesta}>
			<small className="mensaje text-light text-center lh-sm d-block mb-3">¿Te interesa conocer las ventajas de ser FULL CLARO?</small>
			<div className="px-3">
				<Button variant="claro" className="fs-sm-1 w-100 mb-2" size="sm" onClick={handleOpenForm}>
					Si, dime más
				</Button>
				<Button variant="transparent" className="fs-12 border border-light fs-sm-1 w-100 text-light" size="sm" onClick={() => setToggleEncuesta(false)}>
					Ahora no
				</Button>
			</div>
		</MiniForm>

		<MiniForm toggle={toggleForm} setToggle={setToggleForm}>
			<Form onSubmit={handleSubmit}>
				<small className="mensaje text-light text-center lh-sm d-block mb-2">Déjanos tus datos</small>
				<InputNumber placeholder="Ingresa tu Nro. de tel." name="telefono" className="input-miniform mb-2" onChange={handleChange} maxLength="9"/>
				<Form.Control type="email" placeholder="Ingresa tu e-mail" className="input-miniform mb-2" name="email" onChange={handleChange} value={data.email} required/>
				<Form.Check type="checkbox" label="Autorizó el tratamiento de mis datos" className="text-white fs-sm" required/>
				<div className="px-3">
					<Button type="submit" variant="claro" className="fs-sm-1 w-100 mb-2" size="sm">
						Enviar
					</Button>
				</div>		
			</Form>
		</MiniForm>
		<MiniForm toggle={toggleMessage} setToggle={setToggleMessage}>
			<div className="text-light text-center">
				<div className="mb-2">Gracias por tu respuesta.</div>
				<div>Te invitamos a seguir navegando por nuestras ofertas</div>
			</div>
		</MiniForm>
		*/}
	</div>
}