import React from 'react';
import clarovideo from './../../assets/img/icons/claro_video.svg';
import infoIcon  from './../../assets/img/icons/info_icon.svg';
import { OverlayTrigger,Tooltip} from 'react-bootstrap';
const ItemValue = ({tipo,value}) => {

	const imgErrorHandler = (uri,width="300",alt="") => {
		try{
			const image = require(`./../../assets/img/iconsv2/${uri}`);
			return <img src={image} alt="" width={width} className='d-inline-block me-1'/>
		}catch(err){
			return "";
		}
	}

	if(tipo === "RED_SOCIAL"){
		const icons = value.split("|")
		return (
			<div>
				{icons.map( (icon,i) => {
					return imgErrorHandler(icon,17)
				})}
			</div>
		)
	}
	if(tipo === "CLARO_VIDEO"){
		return <img src={clarovideo} width={80}/>
	}
	return <span className="text-end" dangerouslySetInnerHTML={{__html: value}}></span>
}
export const Paquete = ({data}) => {

	return (
		<div className='paquete-item'>
			<p className="d-flex paquete-content mb-0 align-items-center">
				<span className='position-relative'>
					<span className='paquete-label' dangerouslySetInnerHTML={{__html: data.label}}></span>
					{!!data.info && 
					<OverlayTrigger overlay={<Tooltip>{data.info}</Tooltip>}>
						<img src={infoIcon} width={15} style={{position : "absolute", right : "0"}}/>
					</OverlayTrigger>
					}
				</span>
				<ItemValue tipo={data.tipo_paquete} value={data.value}/>
			</p>
		</div>
	)
}