import { API_URL } from "../../constants/urls";

const request = async (option) => {

	const API_AWS = API_URL.API_AWS;

	const requestBody = {
	  "option": option
	}
	const url = API_AWS ;
	//const url = API_AWS + "/ClaroLandingAPI";
	const response = fetch(url,{
		method : "POST",
		body : JSON.stringify(requestBody),
	}).then(resp => {
		if(!resp.ok) throw new Error("E.P") //throw new Error("Error en la petición")
		return resp.json()
	}).catch(console.log)
	return response ;
}

export const listarBanners = async () => {
	const data = await request("opt_banners");
	return data.filter( d => d.activo === 1)
}

export const listarFooterItems = async () => {
	const data = await request("opt_footer_items");
	return data.filter( d => d.activo === 1)
}
export const obtenerLandingInfo = async () => {
	const res = await request("opt_landing_info");
	return res[0];
}