import {useState,useEffect} from 'react';
import { HomeTresPlay,HomeDosPlay, HomeNetflix } from './pages/Home'
import { Moviles } from './pages/Moviles'
import { NotFound } from './pages/Errors';
import { MainNavbar } from './components/Header/MainNavbar';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import { ModalState } from './context/Modal/ModalState';
import { useWebService } from './hooks/useWebService';
import {listarBanners,listarFooterItems,obtenerLandingInfo} from './services/LandingInfo';
import { Spinner } from 'react-bootstrap';

function App() {
  const [height,setHeight] = useState(0);

  const {banners,setBanners,footerItems,setFooterItems,landingInfo,setLandingInfo,setIsLoading,isLoading} = useWebService();
  useEffect(() => {
    const request = async () => {
      try{
        setIsLoading(true);
        if(banners.length === 0){
          const responseBanners = await listarBanners();
          setBanners(responseBanners);
        }
        if(footerItems.length === 0){
          const responseFooterItems = await listarFooterItems();
          setFooterItems(responseFooterItems);
        }


        const responseLadingInfo = await obtenerLandingInfo();
        // setLandingInfo( prev => {
        //   if(!!responseLadingInfo){
        //     return responseLadingInfo;
        //   }
        //   return prev;
        // });
      }
      catch(err){
        console.log(err.message)
      }
      finally{
        setIsLoading(false)
      }
      
    }
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <div className="position-relative">
      {isLoading
      ?<div className="main-loader center-x center-y">
        <Spinner/>
      </div>
      :<BrowserRouter>
        <ModalState>
          <MainNavbar setHeight={setHeight}/>
          <Routes>   
            <Route index element={<Navigate to="/moviles" replace/>}/>
            <Route path="/moviles" element={<Moviles height={height}/>}/>
            {/*<Route path="/hogar/dosplay" element={<HomeDosPlay height={height}/>}/>
            <Route path="/hogar/tresplay" element={<HomeTresPlay height={height}/>}/>
            <Route path="/hogar/netflix" element={<HomeNetflix height={height}/>}/>*/}
            <Route path="*" element={<NotFound height={height}/>}/>
          </Routes>
        </ModalState>
      </BrowserRouter>}
      

    </div>
  );
}

export default App;
