import React from 'react';

export const Image = ({src,width,...props}) => {


    try{
        const image = require(`./../../assets/${src}`);
        return <img src={image} width={width} {...props}/>
    }catch(err){
        return "";
    }
}